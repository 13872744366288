<template>
    <b-modal id="form-disposal" no-close-on-backdrop no-close-on-esc size="lg" title="Pilih Asset">
        <b-table :fields="fields" :items="penyimpanans">
            <template #cell(checkbox)="{item}">
                <b-form-checkbox v-if="item.stok > 0" v-model="selectedPenyimpanans" :value="item"></b-form-checkbox>
                <feather-icon icon="SlashIcon" class="text-danger" size="24" v-else></feather-icon>
            </template>
            <template #cell(nama)="{item}">
                {{item.asset ? item.asset.nama : '-'}}
            </template>
            <template #cell(blok)="{item}">
                {{item.blok ? item.blok.blok : '-'}}
            </template>
            <template #cell(satuan)="{item}">
                {{item.asset && item.asset.satuan ? item.asset.satuan.satuan : ''}}
            </template>
        </b-table>
        <template #modal-footer>
            <b-button variant="primary" :disabled="selectedPenyimpanans.length < 1" @click.prevent="submit">
                Pilih
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BTable, BFormCheckbox, BButton} from 'bootstrap-vue'
export default {
    components: {
        BModal, BTable, BFormCheckbox, BButton
    },
    data: () => ({
        selectedPenyimpanans: [],
        fields: [
            {
                key: 'checkbox',
                label: '#'
            },
            {
                key: 'nama',
                label: 'Aset'
            },
            {
                key: 'blok',
                label: 'Lokasi Aset'
            },
            {
                key: 'stok',
                label: 'Stok'
            },
            {
                key: 'satuan',
                label: 'Satuan'
            }
        ]
    }),
    computed: {
        penyimpanans() {
            let penyimpanans = this.$store.state.asset.stocks
            if(!penyimpanans) {
                return []
            }
            penyimpanans = JSON.parse( JSON.stringify(penyimpanans) )
            penyimpanans.map(item => item.jumlah = item.stok)
            return penyimpanans
        }
    },
    methods: {
        submit() {
            if(!this.selectedPenyimpanans) {
                this.displayError({
                    message: 'Harap pilih aset!'
                })
                return false
            }

            this.$emit('submit', this.selectedPenyimpanans)
        }
    }
}
</script>